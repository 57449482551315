<template>
  <div class="mb-3">
    <b-tabs pills>
      <b-tab title="Daily Causelist" active lazy>
        <daily-causelist />
      </b-tab>
      <b-tab activ title="Archived Causelist" lazy>
        <archived-causelist />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BButton } from 'bootstrap-vue'
import DailyCauselist from './daily/DailyCauselist.vue'
import ArchivedCauselist from './archived/ArchivedCauselist.vue'
import moment from 'moment'

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    DailyCauselist,
    ArchivedCauselist
  },
  data() {
    return {
      interval: null
    }
  },
  beforeMount() {
    this.startInterval()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    startInterval() {
      const beforeTime = moment('05:00 PM', 'LT').format('HH')
      const afterTime = moment('09:00 AM', 'LT').format('HH')
      const now = moment().format('HH')
      const day = moment().format('dddd')
      if (day !== 'Sunday') {
        if (now > afterTime && now < beforeTime) {
          this.$store.dispatch('displayboardStore/fetchUserDisplayBoard')
          clearInterval(this.interval)
          this.interval = setInterval(
            () =>
              this.$store.dispatch('displayboardStore/fetchUserDisplayBoard'),
            60000
          )
        }
      }
    }
  }
}
</script>
