import { ref, watch, computed } from '@vue/composition-api'
// import store from '@/store'
import store from '@/store/index.js'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useArchivedList() {
  // Use toast
  // const toast = useToast()

  const refArchivedListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'unique_id', label: 'Case Ref. Number', sortable: false },
    { key: 'datetime', label: 'Date', sortable: false },
  ]
  const perPage = ref(15)
  const totalLists = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refArchivedListTable.value
      ? refArchivedListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalLists.value
    }
  })

  const refetchData = () => {
    refArchivedListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchArchived = (ctx, callback) => {
    console.log("Archivedinf")
    store
      .dispatch('causelistStore/fetchArchivedCauselist', {perPage: perPage.value, page: currentPage.value})
      .then((response) => {
        console.log({ ArchivedList: response })
        if (response.success) {
          store.dispatch('causelistStore/setArchiveList', {payload: response.data})
          callback(response.data)
          totalLists.value = response.meta.total
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return {
    fetchArchived,
    tableColumns,
    perPage,
    currentPage,
    totalLists,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refArchivedListTable,

    statusFilter,

    refetchData
  }
}