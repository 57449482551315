<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <b-overlay
      :show="$store.state.causelistStore.archivedCauselistLoading"
      rounded="sm"
      variant="transparent"
      opacity="0.5"
      blur="2px"
    >
      <!-- <p>{{ $store.state.causelistStore.archivedCauselists }}</p> -->
      <b-card
        v-if="
          !$store.state.causelistStore.archivedCauselists.length &&
          !$store.state.causelistStore.archivedCauselistLoading
        "
      >
        <p class="text-center mt-5">Record Not Found!</p>
      </b-card>
      <archived-causelist-list class="mb-2" v-else></archived-causelist-list>
      <b-table
        ref="refArchivedListTable"
        :items="fetchArchived"
        responsive
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        v-show="false"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
      </b-table>
    </b-overlay>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalLists"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { formatDate } from '@core/utils/filter'
import ArchivedCauselistList from './ArchivedCauselistList.vue'

// import store from '@/store'
import useArchivedList from './useArchivedList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BOverlay,

    vSelect,
    ArchivedCauselistList
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
    }
  },
  beforeMount() {

  },
  methods: {

  },

  setup(props) {
    const {
      fetchArchived,
      tableColumns,
      perPage,
      currentPage,
      totalLists,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refArchivedListTable,

      statusFilter,

      refetchData
    } = useArchivedList()

    return {
      fetchArchived,
      tableColumns,
      perPage,
      currentPage,
      totalLists,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refArchivedListTable,

      statusFilter,

      refetchData,
      formatDate,

      avatarText
    }
  }
}
</script>