<template>
  <b-overlay
    :show="
      $store.state.hearingStore.hearingLoading &&
      !$store.state.hearingStore.allHearings.length
    "
    rounded="sm"
    variant="transparent"
    opacity="0.5"
    blur="2px"
  >
    <!-- types -->
    <b-card
      class="m-0 p-0 rounded-0"
      no-body
      :style="
        isDark
          ? 'background-color: #343d55; border-color: #343d55'
          : 'background-color: #f3f2f7; border-color: #e6e9eb'
      "
    >
      <template #header>
        <b-col>
          <b-row class="justify-content-between">
            <b-col class="p-0 m-0">
              <p class="p-0 m-0">Listed Cases</p>
            </b-col>
            <b-col>
              <b-row class="justify-content-end">
                <hearing-filter class="pr-50"></hearing-filter>
                <hearing-download-button></hearing-download-button>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </template>
    </b-card>
    <div :type="$store.state.hearingStore.allHearings">
      <div v-for="causelist in $store.state.hearingStore.allHearings">
        <b-card
          no-body
          class="m-0 p-0 rounded-0"
          :style="
            isDark
              ? 'background-color: #343d55; border-color: #343d55'
              : 'background-color: #f3f2f7; border-color: #e6e9eb'
          "
          :header="causelist.date"
          header-text-variant="black"
          header-raduis="0"
          header-class="m-0 p-1 rounded-0"
        >
          <template #header>
            <b-col class="col-12 text-center">
              <b-row class="justify-content-between">
                <b-col>
                  <h4 class="text-black">{{ causelist.date }}</h4>
                  <div>
                    <p class="text-weight-bold p-0 m-0">{{ court }}</p>
                  </div>
                </b-col>
                <div>
                  <export-hearing-by-date
                    :date="causelist.date"
                  ></export-hearing-by-date>
                </div>
              </b-row>
            </b-col>
          </template>

          <b-card no-body class="p-0 m-0 rounded-0">
            <b-table
              :items="causelist.data"
              :fields="fields"
              ref="refInvoiceListTable"
              responsive
              :primary-key="causelist.data._id"
              show-empty
              empty-text="No records found"
              class="position-relative"
            >
              <template #cell(title)="data">
                <router-link
                  class="mb-1 text-primary"
                  :to="{
                    name: data.item.hc_name.includes('oc-')
                      ? 'dashboard-other-matter-preview'
                      : 'dashboard-matter-preview',
                    params: { id: data.item._id }
                  }"
                  target="_blank"
                >
                  <h6 class="mb-1 text-primary">
                    {{ data.item.title }}
                  </h6>
                </router-link>
              </template>
              <!-- Column: Unique Id -->
              <template #cell(unique_id)="data">
                <b-col>
                  <small v-if="Object.keys(data.item).includes('file_no')"
                    >File No.: {{ data.item.file_no }}</small
                  >
                  <div>
                    <h5 class="font-weight-bold">
                      {{ getUniqueId(data.item) }}
                    </h5>
                  </div>
                  <div v-if="Object.keys(data.item).includes('cause_name')">
                    <small v-if="data.item.cause_name">
                      [{{ data.item.cause_name }}]
                    </small>
                  </div>
                </b-col>
              </template>
              <!-- Column: Unique Id -->
              <template #cell(court)="data">
                <b-col>
                  <div v-if="Object.keys(data.item).includes('ctitle')">
                    <small class="text-secondary font-italic">{{
                      data.item.ctitle
                    }}</small>
                  </div>
                  <div v-if="Object.keys(data.item).includes('causelist_type')">
                    <small
                      class="text-secondary"
                      v-if="data.item.causelist_type"
                      >({{ data.item.causelist_type }})</small
                    >
                  </div>
                  <div v-if="Object.keys(data.item).includes('list_type')">
                    <small class="text-secondary" v-if="data.item.list_type"
                      >(List Type: {{ data.item.list_type }})</small
                    >
                  </div>
                  <div v-if="Object.keys(data.item).includes('stats')">
                    <div v-if="data.item.stats">
                      <div
                        v-if="
                          Object.keys(data.item.stats).includes(
                            'total_fresh_cases'
                          ) ||
                          Object.keys(data.item.stats).includes(
                            'total_add_cases'
                          ) ||
                          Object.keys(data.item.stats).includes(
                            'total_ia_cases'
                          ) ||
                          Object.keys(data.item.stats).includes('ordinary') ||
                          Object.keys(data.item.stats).includes('urgent')
                        "
                      >
                        <small
                          v-if="
                            data.item.stats.total_fresh_cases > 0 ||
                            data.item.stats.total_add_cases > 0 ||
                            data.item.stats.total_ia_cases > 0 ||
                            data.item.stats.ordinary > 0 ||
                            data.item.stats.urgent > 0
                          "
                        >
                          (
                          <small
                            class="text-secondary"
                            v-if="
                              Object.keys(data.item.stats).includes(
                                'total_fresh_cases'
                              )
                            "
                            >F-{{ data.item.stats.total_fresh_cases }}
                          </small>
                          <small
                            class="text-secondary"
                            v-if="
                              Object.keys(data.item.stats).includes(
                                'total_add_cases'
                              )
                            "
                            >ADD-{{ data.item.stats.total_add_cases }}
                          </small>
                          <small
                            class="text-secondary"
                            v-if="
                              Object.keys(data.item.stats).includes(
                                'total_ia_cases'
                              )
                            "
                            >APP-{{ data.item.stats.total_ia_cases }}
                          </small>
                          <small
                            class="text-secondary"
                            v-if="
                              Object.keys(data.item.stats).includes('ordinary')
                            "
                            >ORD-{{ data.item.stats.ordinary }}
                          </small>
                          <small
                            class="text-secondary"
                            v-if="
                              Object.keys(data.item.stats).includes('urgent')
                            "
                            >URG-{{ data.item.stats.urgent }}
                          </small>
                          )
                        </small>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      Object.keys(data.item).includes('serial_number') ||
                      Object.keys(data.item).includes('court_number')
                    "
                  >
                    <small
                      class="text-primary font-weight-bolder text-uppercase"
                      v-if="
                        data.item.court_number !== null ||
                        data.item.serial_number > 0
                      "
                    >
                      Listed:
                    </small>
                    <small
                      v-if="Object.keys(data.item).includes('serial_number')"
                    >
                      <small
                        class="text-primary font-weight-bolder text-uppercase"
                        v-if="data.item.serial_number > 0"
                      >
                        [ Sr No. {{ data.item.serial_number }} ]
                      </small>
                    </small>
                    <small
                      v-if="Object.keys(data.item).includes('court_number')"
                    >
                      <small v-if="checkIfInteger(data.item.court_number)">
                        <small
                          class="text-primary font-weight-bolder text-uppercase"
                        >
                          - [ Court No.
                          {{
                            data.item.court_number <= 0
                              ? getStringCourtNumber(data.item.court_number)
                              : data.item.court_number
                          }}
                          ]
                        </small>
                      </small>
                      <small v-else>
                        <small
                          class="text-primary font-weight-bolder text-uppercase"
                          v-if="data.item.court_number !== null"
                        >
                          - [ Court No.
                          {{ data.item.court_number }}
                          ]
                        </small>
                      </small>
                    </small>
                    <small
                      v-if="
                        Object.keys(data.item).includes('transferred_court')
                      "
                    >
                      <small
                        class="text-success font-weight-bolder"
                        v-if="
                          data.item.transferred_court != null &&
                          data.item.transferred_court != -1
                        "
                      >
                        - [ TF to CT
                        {{
                          data.item.transferred_court <= 0
                            ? getStringCourtNumber(data.item.transferred_court)
                            : data.item.transferred_court
                        }}
                        ]
                      </small>
                    </small>
                  </div>
                  <div v-if="Object.keys(data.item).includes('judges')">
                    <div v-if="data.item.judges">
                      <small
                        class="text-black font-weight-bold"
                        v-if="data.item.judges.length"
                        >{{
                          data.item.hc_name.includes('hc') ||
                          data.item.hc_name.includes('sci')
                            ? data.item.judges.length > 1
                              ? 'JUSTICES'
                              : 'JUSTICE'
                            : ''
                        }}
                        {{ data.item.judges.join(', ') }}
                      </small>
                    </div>
                  </div>
                  <div
                    v-if="
                      data.item.hc_name.includes('hc') ||
                      data.item.hc_name.includes('sci')
                    "
                  >
                    <small
                      v-if="
                        Object.keys(data.item).includes('serial_number') &&
                        Object.keys(data.item).includes('court_number')
                      "
                    >
                      <small
                        v-if="
                          data.item.court_number !== null &&
                          data.item.serial_number > 0
                        "
                      >
                        <hearing-display-court
                          :date="causelist.date"
                          :court_id="data.item.court_id"
                          :court_no="
                            Object.keys(data.item).includes(
                              'transferred_court'
                            ) &&
                            data.item.transferred_court != null &&
                            data.item.transferred_court != -1
                              ? data.item.transferred_court
                              : data.item.court_number
                          "
                          :serial_no="data.item.serial_number"
                        ></hearing-display-court>
                      </small>
                    </small>
                  </div>
                </b-col>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-row class="text-nowrap">
                  <b-col cols="auto" class="align-self-center col-8">
                    <div class="text-right">
                      <router-link
                        class="mb-1 text-primary"
                        :to="{
                          name: data.item.hc_name.includes('oc-')
                            ? 'dashboard-other-matter-preview'
                            : 'dashboard-matter-preview',
                          params: { id: data.item._id }
                        }"
                        target="_blank"
                      >
                        <b-badge
                          variant="primary"
                          pill
                          class="badge-round mr-1"
                        >
                          <feather-icon
                            :id="`matter-${data.item._id}-preview-icon`"
                            icon="EyeIcon"
                            size="16"
                            class="mr-25"
                          />
                          <span>View</span>
                        </b-badge>
                      </router-link>
                    </div>
                    <div
                      v-if="Object.keys(data.item).includes('last_order_url')"
                    >
                      <b-badge
                        variant="light"
                        pill
                        class="badge-round mt-2 cursor-pointer"
                        v-if="data.item.last_order_url"
                        @click="
                          exportJudgment({
                            url: data.item.last_order_url,
                            date: data.item.last_order.datetime,
                            matter_id: data.item.unique_id
                          })
                        "
                      >
                        <feather-icon
                          :id="`order-${data.item._id}-preview-icon`"
                          icon="Link2Icon"
                          size="16"
                          class="mr-25 text-primary"
                        />
                        <span class="text-primary">Last Order</span>
                      </b-badge>
                    </div>
                  </b-col>
                </b-row>
              </template>
            </b-table>
          </b-card>
        </b-card>
      </div>
    </div>
    <b-card
      v-if="
        !$store.state.hearingStore.allHearings.length &&
        !$store.state.hearingStore.hearingLoading
      "
    >
      <p class="text-center mt-5">Record Not Found!</p>
    </b-card>
  </b-overlay>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { VueGoodTable } from 'vue-good-table'
import ExportHearingByDate from '../../hearings/components/ExportHearingByDate.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import HearingFilter from '../HearingFilter.vue'
import HearingDisplayCourt from '../../hearings/components/HearingDisplayCourt.vue'
import HearingDownloadButton from '../HearingDownloadButton.vue'

import {
  BCard,
  BCardHeader,
  BButton,
  BCollapse,
  BCardBody,
  BTable,
  BBadge,
  BCol,
  BRow,
  BDropdown,
  BDropdownItem,
  BCardText,
  VBTooltip,
  BOverlay
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    BCard,
    BCardHeader,
    BButton,
    BCollapse,
    BCardBody,
    BCardText,
    BTable,
    BBadge,
    BCol,
    BRow,
    BDropdown,
    BDropdownItem,
    BOverlay,
    AppCollapse,
    AppCollapseItem,
    VueGoodTable,
    ExportHearingByDate,
    HearingDisplayCourt,
    HearingFilter,
    HearingDownloadButton
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {},
  data() {
    let userData = JSON.parse(localStorage.getItem('userData'))

    return {
      fields: [
        { key: 'unique_id', label: 'Case Ref. Number', sortable: false },
        { key: 'court', label: 'Court', sortable: false },
        { key: 'title', sortable: false },
        { key: 'actions' }
      ],
      court: userData.court.label,
      collapseType: 'border'
    }
  },
  beforeCreate() {
    this.$store.dispatch('hearingStore/fetchHearings')
  },
  beforeMount() {},
  methods: {
    checkIfInteger(court_number) {
      // Parse the input as a number and check if it's an integer
      const parsedValue = parseInt(court_number, 10)
      return Number.isInteger(parsedValue)
    },
    downloadAs(url, name) {
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.setAttribute('download', name)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        })
        .catch((err) => {
          console.log('error', err)
        })
    },

    exportJudgment({ url, date, matter_id }) {
      let dd = date.toString().split('T')[0]
      let uu = matter_id.includes('-')
        ? matter_id.split('-').slice(2).join('/')
        : matter_id
      let href = url.replace(
        'http://s3.mymunshi.live',
        'https://app.mymunshi.in/exports'
      )
      this.downloadAs(href, `${uu}-order-${dd}.pdf`)
    }
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  }
}
</script>
