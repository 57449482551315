var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"type":_vm.$store.state.causelistStore.archivedCauselists}},_vm._l((_vm.$store.state.causelistStore.archivedCauselists),function(causelist){return _c('div',[_c('b-card',{staticClass:"m-0 p-0 rounded-0",style:(_vm.isDark
          ? 'background-color: #343d55; border-color: #343d55'
          : 'background-color: #f3f2f7; border-color: #e6e9eb'),attrs:{"no-body":"","header":causelist.date,"header-text-variant":"black","header-raduis":"0","header-class":"m-0 p-1 rounded-0"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-col',{staticClass:"col-12 text-center"},[_c('b-row',{staticClass:"justify-content-between"},[_c('b-col',[_c('h4',{staticClass:"text-black"},[_vm._v(_vm._s(causelist.date))]),_c('div',[_c('p',{staticClass:"font-weight-bold p-0 m-0"},[_vm._v(_vm._s(_vm.court))])])]),_c('div',[_c('export-hearing-by-date',{attrs:{"date":causelist.date,"type":'archived'}})],1)],1)],1)]},proxy:true}],null,true)},[_c('b-card',{staticClass:"p-0 m-0 rounded-0",attrs:{"no-body":""}},[_c('b-table',{ref:"refInvoiceListTable",refInFor:true,staticClass:"position-relative",attrs:{"items":causelist.data,"fields":_vm.fields,"responsive":"","primary-key":causelist.data._id,"show-empty":"","empty-text":"No records found"},scopedSlots:_vm._u([{key:"cell(title)",fn:function(data){return [_c('router-link',{staticClass:"mb-1 text-primary",attrs:{"to":{
                name: data.item.hc_name.includes('oc-')
                  ? 'dashboard-other-matter-preview'
                  : 'dashboard-matter-preview',
                params: { id: data.item._id }
              },"target":"_blank"}},[_c('h6',{staticClass:"mb-1 text-primary"},[_vm._v(" "+_vm._s(data.item.title)+" ")])])]}},{key:"cell(unique_id)",fn:function(data){return [_c('b-col',[(Object.keys(data.item).includes('file_no'))?_c('small',[_vm._v("File No.: "+_vm._s(data.item.file_no))]):_vm._e(),_c('div',[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getUniqueId(data.item))+" ")])]),(Object.keys(data.item).includes('cause_name'))?_c('div',[(data.item.cause_name)?_c('small',[_vm._v(" ["+_vm._s(data.item.cause_name)+"] ")]):_vm._e()]):_vm._e()])]}},{key:"cell(court_id)",fn:function(data){return [_c('b-col',[(Object.keys(data.item).includes('ctitle'))?_c('div',[_c('small',{staticClass:"text-secondary font-italic"},[_vm._v(_vm._s(data.item.ctitle))])]):_vm._e(),(
                  Object.keys(data.item).includes('serial_number') ||
                  Object.keys(data.item).includes('court_number')
                )?_c('div',[(
                    data.item.court_number > 0 || data.item.serial_number > 0
                  )?_c('small',{staticClass:"text-primary font-weight-bolder text-uppercase"},[_vm._v(" Listed: ")]):_vm._e(),(Object.keys(data.item).includes('serial_number'))?_c('small',[(data.item.serial_number > 0)?_c('small',{staticClass:"text-primary font-weight-bolder text-uppercase"},[_vm._v(" [ Sr No. "+_vm._s(data.item.serial_number)+" ] ")]):_vm._e()]):_vm._e(),(Object.keys(data.item).includes('court_number'))?_c('small',[(_vm.checkIfInteger(data.item.court_number))?_c('small',[(
                        data.item.court_number!== null
                      )?_c('small',{staticClass:"text-primary font-weight-bolder text-uppercase"},[_vm._v(" - [ Court No. "+_vm._s(data.item.court_number <= 0 ? _vm.getStringCourtNumber(data.item.court_number) : data.item.court_number)+" ] ")]):_vm._e()]):_c('small',[(data.item.court_number !== null)?_c('small',{staticClass:"text-primary font-weight-bolder text-uppercase"},[_vm._v(" - [ Court No. "+_vm._s(data.item.court_number)+" ] ")]):_vm._e()])]):_vm._e()]):_vm._e(),(Object.keys(data.item).includes('judges'))?_c('div',[(data.item.judges)?_c('div',[(data.item.judges.length)?_c('small',{staticClass:"text-black font-weight-bold"},[_vm._v(_vm._s(data.item.judges.length > 1 ? 'JUSTICES' : 'JUSTICE')+" "+_vm._s(data.item.judges.join(','))+" ")]):_vm._e()]):_vm._e()]):_vm._e()])]}},{key:"cell(actions)",fn:function(data){return [_c('b-row',{staticClass:"text-nowrap"},[_c('b-col',{staticClass:"align-self-center col-8",attrs:{"cols":"auto"}},[_c('div',{staticClass:"text-right"},[_c('router-link',{staticClass:"mb-1 text-primary",attrs:{"to":{
                      name: data.item.hc_name.includes('oc-')
                        ? 'dashboard-other-matter-preview'
                        : 'dashboard-matter-preview',
                      params: { id: data.item._id }
                    },"target":"_blank"}},[_c('b-badge',{staticClass:"badge-round mr-1",attrs:{"variant":"primary","pill":""}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"id":("matter-" + (data.item._id) + "-preview-icon"),"icon":"EyeIcon","size":"16"}}),_c('span',[_vm._v("View")])],1)],1)],1),(Object.keys(data.item).includes('last_order_url'))?_c('div',[(data.item.last_order_url)?_c('b-badge',{staticClass:"badge-round mt-2 cursor-pointer",attrs:{"variant":"light","pill":""},on:{"click":function($event){return _vm.exportJudgment({
                        url: data.item.last_order_url,
                        date: data.item.last_order.datetime,
                        matter_id: data.item.unique_id
                      })}}},[_c('feather-icon',{staticClass:"mr-25 text-primary",attrs:{"id":("order-" + (data.item._id) + "-preview-icon"),"icon":"Link2Icon","size":"16"}}),_c('span',{staticClass:"text-primary"},[_vm._v("Last Order")])],1):_vm._e()],1):_vm._e()])],1)]}}],null,true)})],1)],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }