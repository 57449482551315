<template>
  <div>

    <b-badge variant="primary" class="badge-round" @click="initialSetup">
      <feather-icon
        :id="`hearing-filter`"
        icon="FilterIcon"
        size="16"
        class="cursor-pointer"
      />
      <span class="cursor-pointer px-50">Filter</span>
    </b-badge>

    <!-- select 2 demo -->
    <b-modal
      id="modal-hearing-filter"
      title="Filter Causelist"
      no-close-on-backdrop
      centered
      ok-title="submit"
      cancel-variant="outline-secondary"
    >
      <div>
        <p>Select Dates:</p>
        <b-form-checkbox-group v-model="selectedDates">
          <b-form-checkbox
            v-for="(date, index) in datesList"
            :key="index"
            :value="date"
          >
            {{ date }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </div>

      <div class="pt-2">
        <p>Select Courts:</p>
        <b-form-checkbox-group v-model="selectedCourts">
          <b-form-checkbox
            v-for="(court, index) in courtsList"
            :key="index"
            :value="court"
          >
            {{ court.ctitle }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </div>

      <!-- Footer -->
      <template #modal-footer>
        <!-- By CNR Footer -->
        <div class="w-100">
          <div>
            <b-button
              variant="primary"
              class="float-right"
              type="submit"
              @click="onFilter"
            >
              Filter
            </b-button>
          </div>
          <div>
            <b-button
              variant="outline-dark"
              class="float-right mr-1 ml-1"
              type="cancel"
              @click="onClear"
            >
              Clear
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BCardText,
  BFormTextarea,
  BFormGroup,
  BTabs,
  BTab,
  BBadge,
  BOverlay,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormRadio,
  BSpinner,
  BFormInvalidFeedback,
  BFormDatepicker,
  BListGroup,
  BListGroupItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BBadge,
    BFormCheckboxGroup,
    BCardText,
    BFormInvalidFeedback,
    BFormGroup,
    BTab,
    BTabs,
    vSelect,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BSpinner,
    BFormDatepicker,
    BListGroup,
    BListGroupItem,
    VueAutosuggest
  },
  directives: {
    'b-modal': VBModal,
    Ripple
  },
  computed: {
    datesList() {
      return this.$store.state.hearingStore.datesList
    },
    courtsList() {
      return this.$store.state.hearingStore.courtsList
    }
  },
  data() {
    return {
      // validation rules
      selectedDates: [],
      selectedCourts: [],
      required
    }
  },
  methods: {
    initialSetup() {
      this.$bvModal.show('modal-hearing-filter');
      this.selectedDates = store.state.hearingStore.selectedDatesList
      this.selectedCourts = store.state.hearingStore.selectedCourtsList
    },
    onFilter() {
      console.log('Dates: ', this.selectedDates)
      console.log('Courts: ', this.selectedCourts)

      store.dispatch('hearingStore/selectDateCourtFilter', {
        ctx: this,
        dates: this.selectedDates,
        courts: this.selectedCourts
      })
    },

    onClear() {
      this.selectedCourts = []
      this.selectedDates = []

      store.dispatch('hearingStore/clearHearingFilter', {
        ctx: this,
        dates: this.selectedDates,
        courts: this.selectedCourts
      })
    },

    onDateSelect(date) {
      if (this.selectedDates.includes(date)) {
        // Remove the date from the selectedDatesList array
        const index = this.selectedDates.indexOf(date)
        this.selectedDates.splice(index, 1)
      } else {
        // Add the date to the selectedDatesList array
        this.selectedDates.push(date)
      }
    },

    onCourtSelect(court) {
      // Check if the court is already in selectedCourtsList
      const index = this.selectedCourts.findIndex(
        (selectedCourt) => selectedCourt.court_id === court.court_id
      )

      if (index !== -1) {
        // If the court is found, remove it from selectedCourtsList
        this.selectedCourts.splice(index, 1)
      } else {
        // If the court is not found, add it to selectedCourtsList
        this.selectedCourts.push(court)
      }
    }
  },
  setup(props, context) {
    const { refFormObserver, getValidationState, resetForm } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
