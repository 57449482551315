<template>
  <div>
    <b-badge variant="primary" class="badge-round" @click="onDownload">
      <feather-icon
        :id="`hearing-download`"
        icon="DownloadIcon"
        size="16"
        class="cursor-pointer"
      />
      <span class="cursor-pointer px-50">Download</span>
    </b-badge>
  </div>
</template>

<script>
import {
  BButton,
  BBadge,
} from 'bootstrap-vue'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BButton,
    BBadge,
  },
  directives: {
    Ripple
  },
  methods: {
    onDownload() {
      console.log('Dates: ', this.selectedDates)
      console.log('Courts: ', this.selectedCourts)

      store.dispatch(
        'hearingStore/downloadHearing',
        {ctx: this}
      ).then((response) => {
          if(response != null && response.success){
            this.downloadAs(response.url, `filtered-causelist.pdf`)
          }
      })
    },
    downloadAs(url, name) {
        axios
        .get(url, {
            headers: {
            'Content-Type': 'application/octet-stream'
            },
            responseType: 'blob'
        })
        .then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.setAttribute('download', name)
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            URL.revokeObjectURL(url)
        })
        .catch((err) => {
            console.log('error', err)
        })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
